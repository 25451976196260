import logo from './logo.svg';
import './App.css';
import img_1 from "./img/img_1.webp";
import {Button, Container, Grid, Typography} from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});
function App() {
    const dark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    return (
        <ThemeProvider theme={dark?darkTheme:lightTheme}>
            <CssBaseline />
            <div style={{padding:"64px", boxSizing:"border-box",height:"100vh",}}>
                <Typography variant="h3" style={{textAlign:"center"}}>
                    Thank you!
                </Typography>
                <div style={{ height:"100%", display:"flex",justifyContent:"space-around", alignItems:"center", flexWrap:"wrap",rowGap:"1rem" }} >
                    <img style={{maxWidth:"100%", width:"512px"}} src={img_1} alt="cover"/>
                    <span style={{width:"256px", maxWidth:"100%"}}>
                <Button fullWidth variant="contained" size="large" href="https://paypal.me/owost" >PayPal</Button>
            </span>
                </div>
            </div>

        </ThemeProvider>
)
}

export default App;
